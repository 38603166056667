import React from 'react';
import { Select, Text, Box } from '@chakra-ui/react';

const CustomSelect = ({
  title = 'Title',
  options,
  onSelectChange,
  onSelectBlur,
  selectValue,
  selectName,
  selectId,
  placeholder,
  error,
  textColor = '',
  fontSize,
  textStyles = {},
  fontFamily,
  fontWeight,
  boxStyles,
}) => {
  return (
    <>
      <Box height={'90px'}>
        <Text
          mb={['8px']}
          lineHeight={['16px', '17px']}
          fontSize={fontSize || ['12px', '14px']}
          fontWeight={fontWeight || '500'}
          color={textColor || 'black'}
          fontFamily={fontFamily || 'Montserrat'}
          {...textStyles}
        >
          {title}
        </Text>
        <Box
          display={'flex'}
          flexDirection={['row']}
          alignItems={['center']}
          {...boxStyles}
          border={error ? '1px solid #F10000 !important' : ''}
          style={{
            width: '100%',
            border: 'none',
            borderRadius: '5px',
          }}
        >
          <Select
            w={['100%', '100%']}
            onChange={onSelectChange}
            onBlur={onSelectBlur}
            value={selectValue}
            name={selectName}
            id={selectId}
            mb={['2px']}
            bg={'white'}
            boxShadow='none !important'
            className='selectInput'
            border='1px solid #d9d9d9 !important'
            fontSize={['14px']}
            _focus={{
              borderColor: 'none !important',
              boxShadow: 'none',
            }}
          >
            <option value='' fontSize={['12px', '14px']} >

              {placeholder || '-- Select --'}
            </option>
            {options.map((item, index) => (
              <option
                key={index}
                value={item.value}
                fontSize={['14px']}
                w='70vw'
              >
                {item.label}
              </option>
            ))}
          </Select>
        </Box>
        {error}
      </Box>
    </>
  );
};

export default CustomSelect;
