import React from 'react';
import {
  Box,
  Container,
  Link,
  Stack,
  Text,
  Heading,
  Grid,
  GridItem,
  Image,
  Icon,
  Flex,
  Center,
} from '@chakra-ui/react';
import { NavLink as RouterLink } from 'react-router-dom';
import { AccountRoutes, GeneralRoutes } from '../../utils/routes';
import AltBank_Logo from '../../assets/Home/general/altbank-logo.png';
import CBN_Logo from '../../assets/Home/general/cbn-logo.png';
import AltPower_Logo_Light from '../../assets/Home/general/altpower_logo_light.png';
import { FaLongArrowAltUp } from 'react-icons/fa';

const Footer = () => {
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Box
        bg={'brand.30'}
        color={'white'}
        fontSize={'14px'}
        w='100%'
        pt={24}
        pb={10}
        position='relative'
      >
        <Container as={Stack} maxW={'6xl'} py={10}>
          <Grid
            templateColumns={['repeat(2, 1fr)', 'repeat(5, 1fr)']}
            gap={[8, 4]}
            mx={[0, 12]}
          >
            <GridItem colSpan={1}>
              <Stack align={'flex-start'}>
                <Link as={RouterLink} to={GeneralRoutes.about}>
                  About Us
                </Link>
                <Stack direction={'row'} align={'center'} spacing={2}>
                  <Link as={RouterLink} to={GeneralRoutes.terms}>
                    Terms & Conditions
                  </Link>
                </Stack>
                <Link as={RouterLink} to={AccountRoutes.dashboard}>
                  My Account
                </Link>
              </Stack>
            </GridItem>

            <GridItem colSpan={1}>
              <Stack align={'flex-start'}>
                <Link as={RouterLink} to={GeneralRoutes.deliveryInfo}>
                  Delivery Information
                </Link>
                <Link as={RouterLink} to={GeneralRoutes.paymentOptions}>
                  Payment Options
                </Link>
                <Link as={RouterLink} to={AccountRoutes.orderHistory}>
                  Order History
                </Link>
                <Link as={RouterLink} to={GeneralRoutes.paas}>
                  Power As A Service
                </Link>
              </Stack>
            </GridItem>
            <GridItem colSpan={[2, 1]}>
              <Stack align={'flex-start'}>
                <Link as={RouterLink} to={GeneralRoutes.privacyPolicy}>
                  Privacy Policy
                </Link>
                <Link as={RouterLink} to={GeneralRoutes.returnPolicy}>
                  Return Policy
                </Link>
                <Link as={RouterLink} to={GeneralRoutes.faq}>
                  FAQ
                </Link>
              </Stack>
            </GridItem>
            <GridItem colSpan={[1, 2]}>
              <Stack id='contact-section' align={['flex-start', 'flex-end']}>
                <Link href={'https://www.altbank.ng'} target={'_blank'}>
                  <Image
                    src={AltPower_Logo_Light}
                    alt={'Altpower Logo'}
                    w='120px'
                    my='20px'
                    mr='10px'
                  ></Image>
                </Link>
                <Heading as='h6' fontSize={'24px'} fontWeight={300}>
                  <Link href={'tel:02017000555'}>0201 700 0555</Link>
                </Heading>
                <Text color={'brand.10'}>
                  <Link href={'mailto:service@altpower.ng'}>
                    service@altpower.ng
                  </Link>
                </Text>
              </Stack>
            </GridItem>
          </Grid>
        </Container>

        {/* Licence Disclaimer */}
        <Center>
          <Flex
            w='fit-content'
            justify='center'
            color='#8A8894'
            fontSize={['12px', '16px']}
            fontWeight={700}
            py={10}
          >
            <Text
              as='span'
              pt={6}
              ml={['20px', 'auto']}
              textAlign={['left', 'center']}
            >
              Licensed by CBN{' '}
            </Text>
            <Image
              src={CBN_Logo}
              alt={'CBN Logo'}
              w='20px'
              my='20px'
              mr='10px'
              ml='5px'
            ></Image>
            <Text
              as='span'
              pt={6}
              ml={['20px', 'auto']}
              mr='10px'
              textAlign={['left', 'center']}
            >
              |
            </Text>

            <Text
              as='span'
              pt={6}
              ml={['20px', 'auto']}
              textAlign={['left', 'center']}
            >
              Powered by
            </Text>
            <Link href={'https://www.altbank.ng'} target={'_blank'}>
              <Image
                src={AltBank_Logo}
                alt={'Altbank Logo'}
                w='75px'
                my='20px'
                mx='5px'
              ></Image>
            </Link>
          </Flex>
        </Center>
      </Box>
      <Box
        bg='brand.10'
        w='76px'
        h='320px'
        borderRadius={'15px 0px 0px 0px'}
        position='absolute'
        right={'0'}
        bottom={'0'}
        textAlign='center'
        _hover={{
          cursor: 'pointer',
        }}
        onClick={scrollUp}
      >
        <Icon as={FaLongArrowAltUp} color='white' h='40px' mt='30px' />
      </Box>
    </>
  );
};

export default Footer;
