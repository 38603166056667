import { useQuery } from 'react-query';
import axiosInstance from './api';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCategories,
  getSubCategories,
} from '../redux/reducers/productSlice';
import {
  // setAdditionalCustomerInfo,
  setCustomerMatrix,
} from '../redux/reducers/userSlice';
import { getAccessToken, getLocal, setLocal } from '../hooks/auth';

// // GET USER INFORMATION
// const getCustomerInfo = async () => {
//   const { data } = await axiosInstance.get(
//     `/Customers/getcustomeraccountinformation`
//   );
//   return data;
// };

// export const useGetCustomerInfo = () => {
//   const dispatch = useDispatch();

//   const authStatus = useSelector((state) => state.user?.isAuthenticated);
//   const isAuthenticated = getLocal('auth') || authStatus;

//   const { data, refetch, isLoading } = useQuery(
//     'userInfo',
//     getCustomerInfo, // Use the function directly, it will be called regardless of isAuthenticated
//     {
//       enabled: isAuthenticated, // Fetch data only if isAuthenticated is true
//       refetchOnWindowFocus: false,
//       refetchOnMount: false,
//       staleTime: 24 * 60 * 60 * 1000,
//       onSuccess: (data) => {
//         // console.log('User info response', data);
//         dispatch(setAdditionalCustomerInfo(data?.data));
//         setLocal('info', data?.data);
//       },
//     }
//   );

//   return {
//     getMoreUserInfo: data,
//     isLoadingMoreUserInfo: isLoading,
//     refetchMoreUserInfo: refetch,
//   };
// };

// GET USER MATRIX
const getCustomerMatrix = async () => {
  const { data } = await axiosInstance.get(`/Customers/customermatrix`);
  return data;
};

export const useGetCustomerMatrix = () => {
  const dispatch = useDispatch();

  const authStatus = useSelector((state) => state.user?.isAuthenticated);
  const isAuthenticated = getLocal('auth') || authStatus;

  const { data, refetch, isLoading } = useQuery(
    'userMatrix',
    () => getCustomerMatrix(),
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 24 * 60 * 60 * 1000,
      onSuccess: (data) => {
        // console.log('User Matrix response', data);
        dispatch(setCustomerMatrix(data?.data));
        setLocal('customerMatrix', data?.data);
      },
    }
  );
  return {
    getCustomerMatrix: data,
    isLoadingCustomerMatrix: isLoading,
    refetchCustomerMatrix: refetch,
  };
};

// GET ALL CATEGORIES
const getAllCategories = async () => {
  const { data } = await axiosInstance.get(`/Category/getallcategories`);
  return data;
};

export const useGetAllCategories = () => {
  const dispatch = useDispatch();
  const { data, refetch, isLoading } = useQuery(
    'allCategories',
    () => getAllCategories(),
    {
      refetchOnMount: true,
      enabled: document.hasFocus(), // Disable initial fetch if window is not focused
      staleTime: Infinity, // Disable refetch on window focus
      manual: true, // Enable manual triggering
      onSuccess: (data) => {
        // dispatch(getCategories(data?.data));
        // console.log('GET ALL CATEGORIES', data);
        const sortedData = data?.data?.sort((a, b) =>
          a.categoryId.localeCompare(b.categoryId)
        );
        dispatch(getCategories(sortedData));
        setLocal('categories', sortedData);
      },
    }
  );

  return {
    allCategories: data,
    isLoadingCategories: isLoading,
    refetchCategories: refetch,
  };
};

// GET ALL SUBCATEGORIES
const getAllSubCategories = async () => {
  const { data } = await axiosInstance.get(
    `/SubCategory/getallsubcategories?PageNumber=${1}&PageSize=${100}`
  );
  return data;
};

export const useGetAllSubCategories = () => {
  const dispatch = useDispatch();
  const { data, refetch, isLoading } = useQuery(
    'allSubCategories',
    () => getAllSubCategories(),
    {
      refetchOnMount: true,
      enabled: document.hasFocus(), // Disable initial fetch if window is not focused
      staleTime: Infinity, // Disable refetch on window focus
      manual: true, // Enable manual triggering
      onSuccess: (data) => {
        // console.log('subCat', data);
        dispatch(getSubCategories(data?.data));
        setLocal('subCategories', data?.data);
      },
    }
  );

  return {
    allSubCategories: data,
    isLoadingSubCategories: isLoading,
    refetchSubCategories: refetch,
  };
};

// GET SINGLE PRODUCT INFORMATION
const fetchSingleProduct = async (productId) => {
  const { data } = await axiosInstance.get(
    `/Product/getSingleCustomerProduct?productId=${productId}`
  );
  return data;
};

export const useSingleProduct = (productId) => {
  return useQuery(['product', productId], () => fetchSingleProduct(productId), {
    refetchOnMount: true,
    enabled: document.hasFocus(),
    staleTime: Infinity,
    manual: true,
  });
};

const fetchAddresses = async () => {
  const { data } = await axiosInstance.get(
    `/Address/getcustomeralldeliveryaddress`
  );
  return data;
};

// GET ALL ADDRESSES
export const useFetchAllAddresses = () => {
  const { data, refetch, isLoading } = useQuery(
    'addresses',
    () => fetchAddresses(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 24 * 60 * 60 * 1000,
      onSuccess: (data) => {
        // console.log('Address', data);
      },
    }
  );
  return {
    getAddresses: data,
    isLoadingAddresses: isLoading,
    refetchAddresses: refetch,
  };
};

// GET RECOMMENDED PRODUCTS
const fetchRecommendedProducts = async () => {
  const accessToken = getAccessToken();
  if (accessToken) {
    const { data } = await axiosInstance.get(`/Product/gettopdealproducts`);
    // console.log(data)
    return data.data;
  } else {
    // console.log('done');
    return [];
  }
};

export const useRecommendedProducts = () => {
  return useQuery('recommended', () => fetchRecommendedProducts(), {
    refetchOnMount: true,
    enabled: document.hasFocus(),
    staleTime: Infinity,
    manual: true,
  });
};

// GET PRODUCT REVIEWS
const fetchProductReview = async (productId) => {
  const { data } = await axiosInstance.get(
    `/Customers/getproductreview?productId=${productId}`
  );
  return data;
};

export const useGetProductReview = (productId) => {
  return useQuery(
    ['productReview', productId],
    () => fetchProductReview(productId),
    {
      refetchOnMount: true,
      enabled: document.hasFocus(),
      staleTime: Infinity,
      manual: true,
    }
  );
};

// GET PAYLATER TRACk
const fetchTrackPaylater = async () => {
  const { data } = await axiosInstance.get(`/Paylater/trackcustomerpaylater`);
  return data;
};
export const useFetchTrackPaylater = () => {
  return useQuery(['trackPaylater'], () => fetchTrackPaylater(), {
    refetchOnMount: true,
    enabled: document.hasFocus(),
    staleTime: Infinity,
    manual: true,
  });
};

// GET PAYLATER METRIX
const fetchPaylaterMatrix = async () => {
  const { data } = await axiosInstance.get(`/Paylater/paylatercustomermatrix`);
  return data;
};
export const useFetchPaylaterMatrix = () => {
  return useQuery(['trackPaylaterMatrix'], () => fetchPaylaterMatrix(), {
    refetchOnMount: true,
    enabled: document.hasFocus(),
    staleTime: Infinity,
    manual: true,
  });
};

const fetchOrderHistory = async (customerId) => {
  try {
    const response = await axiosInstance.get(
      `/Order/getOrderByCustomerId?customerId=${customerId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const useFetchOrderHistory = (customerId) => {
  return useQuery(
    ['orderHistory', customerId],
    () => fetchOrderHistory(customerId),
    {
      refetchOnMount: true,
      enabled: document.hasFocus(),
      staleTime: Infinity,
      manual: true,
    }
  );
};
