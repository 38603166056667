import { createSlice } from '@reduxjs/toolkit';

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {
    cartId: '',
    customerId: '',
    shippingAddressId: '',
    totalQuantity: 0,
    amount: 0,
    deliveryOption: '',
    shippingService: '',
    deliveryFee: 0,
    paymentMethod: '',
    orderId: '',
    bvn: {},
    accInfo: {},
  },
  reducers: {
    setCartId(state, action) {
      state.cartId = action.payload;
    },
    setCustomerId(state, action) {
      state.customerId = action.payload;
    },
    setShippingAddressId(state, action) {
      state.shippingAddressId = action.payload;
    },
    setTotalQuantity(state, action) {
      state.totalQuantity = action.payload;
    },
    setAmount(state, action) {
      state.amount = action.payload;
    },
    setDeliveryOption(state, action) {
      state.deliveryOption = action.payload;
    },
    setShippingService(state, action) {
      state.shippingService = action.payload;
    },
    setDeliveryFee(state, action) {
      state.deliveryFee = action.payload;
    },
    setPaymentMethod(state, action) {
      state.paymentMethod = action.payload;
    },
    setOrderId(state, action) {
      state.orderId = action.payload;
    },
    setBvnInfo(state, action) {
      state.bvn = action.payload;
    },
    setAccInfo(state, action) {
      state.accInfo = action.payload;
    },
  },
});

export const {
  setCartId,
  setCustomerId,
  setShippingAddressId,
  setTotalQuantity,
  setAmount,
  setDeliveryOption,
  setShippingService,
  setDeliveryFee,
  setPaymentMethod,
  setOrderId,
  setBvnInfo,
  setAccInfo,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
