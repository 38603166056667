/* eslint-disable no-unused-vars */
import { Box } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../../components/Navigation/Navbar';
import { getBrowserId } from '../../utils/browserId';
import { useDispatch, useSelector } from 'react-redux';
import { anon } from '../../redux/reducers/userSlice';
import { useFetchCartItems } from '../../hooks/useFetchItems';
import {
  useGetAllCategories,
  useGetAllSubCategories,
} from '../../services/apiURLs';
import { getLocal } from '../../hooks/auth';
import LoadingModal from '../../components/Modals/LoadingModal';
import axiosInstance from '../../services/api';

const MainLayout = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user?.userData);
  const userData = getLocal('user') || userInfo;
  const browserId = useSelector((state) => state.user?.browserId);
  const { cartItems } = useFetchCartItems(userData?.userId || '', browserId);
  const { allCategories } = useGetAllCategories();
  const { allSubCategories } = useGetAllSubCategories();

  React.useEffect(() => {
    getBrowserId();
    const browserId = getBrowserId();
    dispatch(anon(browserId));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box w={'100%'} bg='brand.40' position={'relative'}>
        <Box w={'100%'} position={'fixed'} top={0} left={0}>
          <Box px={[8, 24]} bg={'brand.40'} zIndex={'2'}>
            <Navbar />
          </Box>
        </Box>
        <Box
          bg={'brand.40'}
          px={['auto']}
          position={'absolute'}
          top={'95px'}
          zIndex={'-2 !important'}
          w={'100%'}
        >
          <Suspense fallback={<LoadingModal />}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>
    </>
  );
};

export default MainLayout;
