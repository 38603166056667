import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userSlice';
import productReducer from './reducers/productSlice';
import cartReducer from './reducers/cartSlice';
import checkoutReducer from './reducers/checkoutSlice';
import { combineReducers } from '@reduxjs/toolkit';
import { tokenRefreshMiddleware } from '../hooks/tokenRefreshMiddleware';


const reducer = combineReducers({
  user: userReducer,
  products: productReducer,
  cart: cartReducer,
  checkout: checkoutReducer,
});


export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    tokenRefreshMiddleware,
  ],
});

// store.subscribe(() => {
//   console.log('Store', store.getState());
// });
