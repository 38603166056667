import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Button,
  useDisclosure,
  Stack,
  Image,
  Link,
  Text,
  Center,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { FaBars, FaTimes, FaUserCircle } from "react-icons/fa";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { NavLink as RouterLink, useNavigate } from "react-router-dom";
import altpowerLogo from "../../assets/Home/general/altpower_logo.png";
import Cart from "../../assets/Account/Dashboard/cart.svg";
import { AccountRoutes, AppRoutes, GeneralRoutes } from "../../utils/routes";
import CustomDropdown from "../Select/CustomDropdown";
import { useDispatch, useSelector } from "react-redux";
import UserIcon from "../../assets/icons/user.svg";
import SettingsIcon from "../../assets/icons/settings.svg";
import InboxIcon from "../../assets/icons/inbox.svg";
import LogoutIcon from "../../assets/icons/logout.svg";
import { logout } from "../../redux/reducers/userSlice";
import {
  clearAccessToken,
  clearLocal,
  clearRefreshToken,
  getLocal,
  setLocal,
} from "../../hooks/auth";
import axiosInstance from "../../services/api";
import { useCustomMutation } from "../../hooks/useCustomMutation";
import CheckTimer from "./CheckTimer";

const Navbar = () => {
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userInfo = useSelector((state) => state.user.userData);
  const userAuthStatus = useSelector((state) => state.user.isAuthenticated);
  const cartData = useSelector((state) => state.cart.cart);
  const userData = getLocal("user") || userInfo;
  const isAuthenticated = getLocal("auth") || userAuthStatus;
  const cart = getLocal("cart") || cartData;
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const vendorUrl = process.env.REACT_APP_VendorURL;

  const { mutate, isLoading } = useCustomMutation(axiosInstance);

  const handleLogout = () => {
    mutate({
      url: `/Customers/customerlogout`,
      method: "PATCH",
      data: null,
      onSuccess: (data) => {
        if (data?.responseCode === "00") {
          toast({
            title: `${data?.responseMessage}`,
            status: "success",
          });
          dispatch(logout());
          setLocal("auth", false);
          clearLocal("user");
          clearAccessToken();
          clearRefreshToken();
          navigate(AppRoutes.home);
        } else {
          toast({
            title: `${data?.responseMessage}`,
            status: "error",
          });
        }
      },
      onError: (error) => {
        toast({
          title: `${error?.response?.statusText}`,
          status: "error",
        });
      },
    });
  };

  const helpOptions = [
    {
      id: 1,
      value: "FAQs",
      route: () => {
        navigate(GeneralRoutes.faq);
        onClose();
      },
    },
    {
      id: 2,
      value: "Contact Us",
      route: () => {
        navigate(AppRoutes.home);
        setTimeout(() =>
          document
            .getElementById("contact-section")
            .scrollIntoView({ behavior: "smooth" })
        );
        onClose();
      },
    },
    {
      id: 3,
      value: "Return Policy",
      route: () => {
        navigate(GeneralRoutes.returnPolicy);
        onClose();
      },
    },
  ];

  const authOptions = [
    {
      id: 1,
      value: "Sign in",
      route: () => {
        navigate(AppRoutes.signin);
        onClose();
      },
    },
    {
      id: 2,
      value: "Sign up",
      route: () => {
        navigate(AppRoutes.signup);
        onClose();
      },
    },
  ];

  const privateOptions = [
    {
      id: 0,
      value: "My account",
      icon: UserIcon,
      route: () => {
        navigate(AccountRoutes.dashboard);
        onClose();
      },
    },
    {
      id: 1,
      value: "Settings",
      icon: SettingsIcon,
      route: () => {
        navigate(AccountRoutes.settings);
        onClose();
      },
    },
    {
      id: 2,
      value: "Wishlist",
      icon: InboxIcon,
      route: () => navigate(AccountRoutes.saves),
    },
    {
      id: 3,
      value: isLoading ? "Logging out" : "Logout",
      icon: LogoutIcon,
      route: () => {
        handleLogout();
        onClose();
      },
    },
  ];

  return (
    <>
      {isAuthenticated ? (
        <CheckTimer
          handleLogouts={() => {
            handleLogout();
          }}
        />
      ) : null}
      <Flex
        h={24}
        alignItems={"center"}
        justifyContent={"space-between"}
        zIndex={"2 !important"}
      >
        <HStack spacing={8} alignItems={"center"}>
          <Box>
            <Link
              as={RouterLink}
              to={AppRoutes.home}
              _hover={{ textDecoration: "none" }}
            >
              <Image src={altpowerLogo} alt="Altpower Logo" />
            </Link>
          </Box>
        </HStack>
        {isDesktop ? (
          <HStack
            as={"nav"}
            spacing={8}
            display={{ base: "none", md: "flex" }}
            fontSize={["14px"]}
          >
            <Link
              as={RouterLink}
              to={AppRoutes.powerOptions}
              _hover={{ textDecoration: "none" }}
            >
              Power as a Service
            </Link>
            <Link
              as="a"
              href={vendorUrl}
              target="_blank"
              rel="noopener noreferrer"
              _hover={{ textDecoration: "none" }}
            >
              Vendors
            </Link>
            <Box>
              <CustomDropdown
                icon={<Icon as={IoMdHelpCircleOutline} />}
                title="Help"
                options={helpOptions}
                onSelectOption={() => null}
              />
            </Box>

            <Box>
              <CustomDropdown
                icon={<Icon as={FaUserCircle} />}
                title={
                  !isAuthenticated && !userData
                    ? "Account"
                    : `Hello, ${userData.firstName}`
                }
                options={
                  !isAuthenticated && !userData ? authOptions : privateOptions
                }
                onSelectOption={() => null}
              />
            </Box>
            <Link
              as={RouterLink}
              to={AppRoutes.cart}
              _hover={{ textDecoration: "none" }}
            >
              <Button
                variant={"solid"}
                bg={"brand.10"}
                color={"white"}
                colorScheme={"green"}
                size={"sm"}
                fontSize={["14px"]}
              >
                <HStack spacing={2}>
                  <Image src={Cart} alt={"cart icon"} />
                  <Text>Cart</Text>
                  <Center
                    bg={"white"}
                    color={"brand.10"}
                    w="20px"
                    h="20px"
                    borderRadius={"50%"}
                  >
                    <Text fontSize={"8px"}>{cart?.length || 0}</Text>
                  </Center>
                </HStack>
              </Button>
            </Link>
          </HStack>
        ) : (
          <>
            <IconButton
              size={"md"}
              icon={isOpen ? <FaTimes /> : <FaBars />}
              aria-label={"Open Menu"}
              onClick={isOpen ? onClose : onOpen}
            />
          </>
        )}

        {/* </Flex> */}
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: "none" }}>
          <Stack as={"nav"} spacing={4}>
            <Link
              as={RouterLink}
              to={AppRoutes.powerOptions}
              _hover={{ textDecoration: "none" }}
              onClick={() => onClose()}
            >
              <Text>Power as a Service</Text>
            </Link>
            <Link
              as="a"
              href={vendorUrl}
              target="_blank"
              rel="noopener noreferrer"
              _hover={{ textDecoration: "none" }}
              onClick={() => onClose()}
            >
              Vendors
            </Link>
            <HStack>
              <Icon as={IoMdHelpCircleOutline} />
              <CustomDropdown
                title="Help"
                top={"200px"}
                options={helpOptions}
                onSelectOption={() => null}
              />
            </HStack>
            <HStack>
              <Icon as={FaUserCircle} />
              <CustomDropdown
                title={
                  !isAuthenticated && !userData
                    ? "Account"
                    : `Hello, ${userData.firstName}`
                }
                top={"250px"}
                options={
                  !isAuthenticated && !userData ? authOptions : privateOptions
                }
              />
            </HStack>
            <Link
              as={RouterLink}
              to={AppRoutes.cart}
              _hover={{ textDecoration: "none" }}
            >
              <Button
                variant={"solid"}
                bg={"brand.10"}
                color={"white"}
                colorScheme={"green"}
                size={"sm"}
                onClick={() => onClose()}
              >
                <HStack spacing={2}>
                  <Image src={Cart} alt={"cart icon"} />
                  <Text>Cart</Text>
                  <Center
                    bg={"white"}
                    color={"brand.10"}
                    w="20px"
                    h="20px"
                    borderRadius={"50%"}
                  >
                    <Text fontSize={"8px"}>2</Text>
                  </Center>
                </HStack>
              </Button>
            </Link>
          </Stack>
        </Box>
      ) : null}
    </>
  );
};

export default Navbar;
