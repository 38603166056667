import { jwtDecode } from 'jwt-decode';
import CryptoJS from 'crypto-js';

const ENCRYPTION_KEY = String(process.env.REACT_APP_Key);

const encrypt = (text) => {
  return CryptoJS.AES.encrypt(text, ENCRYPTION_KEY).toString();
};

const decrypt = (cipherText) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, ENCRYPTION_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const setCookie = (key, value, expires) => {
  const encryptedValue = encrypt(JSON.stringify(value));
  document.cookie = `${key}=${encryptedValue};expires=${expires};path=/;Secure`;
};

export const getCookie = (key) => {
  try {
    const cookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith(`${key}=`));
    if (!cookie) {
      return null;
    }

    const cookieValue = cookie.split('=')[1];
    if (cookieValue === 'undefined') {
      return null;
    }

    const decryptedValue = decrypt(
      `${cookieValue}${cookie[cookie.length - 1] === '=' && '='}`
    );

    if (decryptedValue === 'undefined') {
      return null;
    }

    try {
      return JSON.parse(decryptedValue);
    } catch (jsonError) {
      return decrypt(cookieValue);
      // console.error('Error parsing JSON from cookie:', jsonError);
    }
  } catch (error) {
    console.error('Error decrypting cookie:', error);
    return null;
  }
};

export const clearCookie = (key) => {
  document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
};

export const setRefreshToken = (refreshToken, customerId, expirationTime) => {
  setCookie(
    'refreshToken',
    { refreshToken, customerId, expirationTime },
    new Date(expirationTime).toUTCString()
  );
};

export const setAccessToken = (accessToken) => {
  setCookie('accessToken', accessToken);
};

export const getRefreshToken = () => {
  const refreshToken = getCookie('refreshToken');
  return refreshToken;
};

export const getAccessToken = () => {
  const token = getCookie('accessToken');

  // console.log('token', token);

  if (token) {
    try {
      const tokenData = jwtDecode(token);
      if (
        tokenData &&
        tokenData.exp &&
        tokenData.exp * 1000 > new Date().getTime()
      ) {
        return token;
      } else {
        return token;
      }
    } catch (error) {
      console.error('Error decoding JWT token:', error);
    }
  }
  return null;
};

export const clearRefreshToken = () => {
  clearCookie('refreshToken');
};

export const clearAccessToken = () => {
  clearCookie('accessToken');
};

// Set encrypted value to local storage
export const setLocal = (key, value) => {
  const encryptedValue = encrypt(JSON.stringify(value));
  localStorage.setItem(key, encryptedValue);
};

// Get and decrypt value from local storage
export const getLocal = (key) => {
  try {
    const encryptedValue = localStorage.getItem(key);
    if (!encryptedValue) {
      return null;
    }

    const decryptedValue = decrypt(encryptedValue);

    try {
      return JSON.parse(decryptedValue);
    } catch (jsonError) {
      console.error('Error parsing JSON from local storage:', jsonError);
      return decryptedValue;
    }
  } catch (error) {
    console.error('Error decrypting local storage:', error);
    return null;
  }
};

// Clear value from local storage
export const clearLocal = (key) => {
  localStorage.removeItem(key);
};

// Set encrypted value to session storage
export const setSession = (key, value) => {
  const encryptedValue = encrypt(JSON.stringify(value));
  sessionStorage.setItem(key, encryptedValue);
};

// Get and decrypt value from session storage
export const getSession = (key) => {
  try {
    const encryptedValue = sessionStorage.getItem(key);
    if (!encryptedValue) {
      return null;
    }

    const decryptedValue = decrypt(encryptedValue);

    try {
      return JSON.parse(decryptedValue);
    } catch (jsonError) {
      console.error('Error parsing JSON from session storage:', jsonError);
      return decryptedValue;
    }
  } catch (error) {
    console.error('Error decrypting session storage:', error);
    return null;
  }
};

// Clear value from session storage
export const clearSession = (key) => {
  sessionStorage.removeItem(key);
};
