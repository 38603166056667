import React, { lazy } from 'react';
import {
  AccountRoutes,
  AppRoutes,
  CheckoutRoutes,
  GeneralRoutes,
} from './routes';
import { createBrowserRouter } from 'react-router-dom';
import PrivateRoute from '../components/Private/PrivateRoute';
import MainLayout from '../pages/MainLayout';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';

const Account = lazy(() => import('../pages/Account'));
const AddBook = lazy(() => import('../pages/Account/subPages/AddBook'));
const AltFinance = lazy(() => import('../pages/Account/subPages/AltFinance'));
const Dashboard = lazy(() => import('../pages/Account/subPages/Dashboard'));
const OrderHistory = lazy(() =>
  import('../pages/Account/subPages/OrderHistory')
);
const Saves = lazy(() => import('../pages/Account/subPages/Saves'));
const Settings = lazy(() => import('../pages/Account/subPages/Settings'));
const Cart = lazy(() => import('../pages/Cart'));
const OrderSummary = lazy(() => import('../pages/Checkout'));
const NewShipping = lazy(() =>
  import('../pages/Checkout/subPages/NewShipping')
);
const Shipping = lazy(() => import('../pages/Checkout/subPages/Shipping'));
const ShippingCo = lazy(() => import('../pages/Checkout/subPages/ShippingCo'));
const AddCashCollateral = lazy(() =>
  import('../pages/Checkout/subPages/paylater/AddCashCollateral')
);
const CreditSummary = lazy(() =>
  import('../pages/Checkout/subPages/paylater/CreditSummary')
);
const PaylaterCheck = lazy(() =>
  import('../pages/Checkout/subPages/paylater/PaylaterCheck')
);
const RequestStatement = lazy(() =>
  import('../pages/Checkout/subPages/paylater/RequestStatement')
);
const AboutUs = lazy(() => import('../pages/General/About'));
const DeliveryInfo = lazy(() => import('../pages/General/DeliveryInfo'));
const FAQ = lazy(() => import('../pages/General/FAQ'));
const PaymentOptions = lazy(() => import('../pages/General/PaymentOptions'));
const PrivacyPolicy = lazy(() => import('../pages/General/PrivacyPolicy'));
const ReturnPolicy = lazy(() => import('../pages/General/ReturnPolicy'));
const TermsNCon = lazy(() => import('../pages/General/TermsNCon'));
const PowerOptions = lazy(() => import('../pages/PowerOptions'));
const PAASRegister = lazy(() =>
  import('../pages/PowerOptions/component/PAASRegister')
);
const Products = lazy(() => import('../pages/Product'));
const ProductDetails = lazy(() => import('../pages/ProductDetails'));
const Signin = lazy(() => import('../pages/Signin'));
const ForgotPassword = lazy(() =>
  import('../pages/Signin/components/ForgotPassword')
);
const ResetPassword = lazy(() =>
  import('../pages/Signin/components/ResetPassword')
);
const Signup = lazy(() => import('../pages/Signup'));
const VerifyEmail = lazy(() => import('../pages/Signup/VerifyEmail'));
const RedirectPayLater = lazy(() =>
  import('../pages/Checkout/subPages/paylater/PayLaterRedirect')
);
const RedirectPayNow = lazy(() =>
  import('../pages/Checkout/subPages/paynow/redirect')
);

const routes = [
  { path: AppRoutes.verifyEmail, element: <VerifyEmail /> },
  {
    path: CheckoutRoutes.payLaterRedirect,
    element: <RedirectPayLater />,
  },
  {
    path: CheckoutRoutes.payNowRedirect,
    element: <RedirectPayNow />,
  },
  {
    path: AppRoutes.home,
    element: <MainLayout />,
    children: [
      { index: true, element: <Home /> },
      { path: AppRoutes.signup, element: <Signup /> },
      { path: AppRoutes.signin, element: <Signin /> },
      { path: AppRoutes.forgotPassword, element: <ForgotPassword /> },
      { path: AppRoutes.resetPassword, element: <ResetPassword /> },
      { path: AppRoutes.singleProduct, element: <ProductDetails /> },
      { path: AppRoutes.productSubcategory, element: <Products /> },
      { path: AppRoutes.productCategory, element: <Products /> },
      { path: AppRoutes.products, element: <Products /> },
      { path: AppRoutes.cart, element: <Cart /> },
      {
        path: AppRoutes.account,
        element: <PrivateRoute />,
        children: [
          {
            element: <Account />,
            children: [
              { path: AccountRoutes.dashboard, element: <Dashboard /> },
              { path: AccountRoutes.orderHistory, element: <OrderHistory /> },
              { path: AccountRoutes.altFin, element: <AltFinance /> },
              { path: AccountRoutes.saves, element: <Saves /> },
              { path: AccountRoutes.addBook, element: <AddBook /> },
              { path: AccountRoutes.settings, element: <Settings /> },
            ],
          },
        ],
      },
      { path: AppRoutes.powerOptions, element: <PowerOptions /> },
      { path: AppRoutes.paasRegister, element: <PAASRegister /> },
      { path: AppRoutes.notfound, element: <NotFound /> },
      { path: GeneralRoutes.about, element: <AboutUs /> },
      { path: GeneralRoutes.terms, element: <TermsNCon /> },
      { path: GeneralRoutes.deliveryInfo, element: <DeliveryInfo /> },
      { path: GeneralRoutes.paymentOptions, element: <PaymentOptions /> },
      { path: GeneralRoutes.privacyPolicy, element: <PrivacyPolicy /> },
      { path: GeneralRoutes.returnPolicy, element: <ReturnPolicy /> },
      { path: GeneralRoutes.faq, element: <FAQ /> },
      {
        element: <PrivateRoute />,
        children: [{ path: CheckoutRoutes.shipping, element: <Shipping /> }],
      },
      {
        element: <PrivateRoute />,
        children: [
          { path: CheckoutRoutes.newShipping, element: <NewShipping /> },
        ],
      },
      {
        element: <PrivateRoute />,
        children: [
          { path: CheckoutRoutes.shippingCo, element: <ShippingCo /> },
        ],
      },
      {
        element: <PrivateRoute />,
        children: [
          { path: CheckoutRoutes.orderSummary, element: <OrderSummary /> },
        ],
      },
      {
        element: <PrivateRoute />,
        children: [
          { path: CheckoutRoutes.payLater, element: <PaylaterCheck /> },
        ],
      },
      {
        element: <PrivateRoute />,
        children: [
          {
            path: CheckoutRoutes.cashCollateral,
            element: <AddCashCollateral />,
          },
        ],
      },
      {
        element: <PrivateRoute />,
        children: [
          {
            path: CheckoutRoutes.requestStatement,
            element: <RequestStatement />,
          },
        ],
      },
      {
        element: <PrivateRoute />,
        children: [
          { path: CheckoutRoutes.creditSummary, element: <CreditSummary /> },
        ],
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
