import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { AppRoutes } from '../../utils/routes';
import { getLocal } from '../../hooks/auth';

const PrivateRoute = ({ children }) => {
  // Check if the user is logged in
  const authStatus = useSelector((state) => state.user?.isAuthenticated);
  const isAuthenticated = getLocal('auth') || authStatus;

  // If the user is logged in, render the component, otherwise redirect to the login page
  return isAuthenticated !== true ? (
    <Navigate to={AppRoutes.signin} />
  ) : (
    <Outlet>{children}</Outlet>
  );
};

export default PrivateRoute;
