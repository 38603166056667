import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cart: [],
    totalAmount: 0,
    totalPrice: 0,
  },
  reducers: {
    setCartItems: (state, action) => {
      state.cart = action.payload;
    },
    setTotalCartAmount: (state, action) => {
      state.totalAmount = action.payload;
    },
    setTotalPrice: (state, action) => {
      state.totalPrice = action.payload;
    },
    addItemToCart: (state, action) => {
      const newItem = action.payload;
      state.cart.push(newItem);
    },
    removeItemFromCart: (state, action) => {
      const itemId = action.payload;
      state.cart = state.cart.filter((item) => item.id !== itemId);
    },
    updateCartDetails: (state, action) => {
      const { itemId, quantity } = action.payload;
      const item = state.cart.find((item) => item.id === itemId);
      if (item) {
        item.quantity = quantity;
      }
    },
  },
});

export const {
  setCartItems,
  setTotalCartAmount,
  setTotalPrice,
  addItemToCart,
  removeItemFromCart,
  updateCartDetails,
} = cartSlice.actions;

export default cartSlice.reducer;
