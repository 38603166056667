import { SHA256 } from 'crypto-js';
// Create or read a cookie with the browser ID
const getBrowserId = () => {
  const cookieName = 'browserId';
  let browserId = getCookie(cookieName);
  if (!browserId) {
    // Generate a new browser ID using fingerprinting
    const fingerprint = createFingerprint();
    browserId = fingerprint.visitorId;
    // Store the browser ID in a cookie that expires in 30 days
    setCookie(cookieName, browserId, 180);
  }
  return browserId;
};

// Function to create a new cookie with a specified name, value, and expiration time
const setCookie = (name, value, days) => {
  const expires = new Date(
    Date.now() + days * 24 * 60 * 60 * 1000
  ).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${expires}; path=/`;
};

// Function to read a cookie with a specified name
const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name}=([^;]*)`));
  return matches ? decodeURIComponent(matches[1]) : null;
};

// Function to create a browser fingerprint
const createFingerprint = () => {
  const fingerprint = {};
  const ua = window.navigator.userAgent;
  fingerprint.userAgent = ua;
  fingerprint.colorDepth = window.screen.colorDepth;
  fingerprint.deviceMemory = navigator.deviceMemory || null;
  fingerprint.pixelRatio = window.devicePixelRatio || 1;
  fingerprint.screenResolution = `${window.screen.width}x${window.screen.height}`;
  fingerprint.timeZone = new Date().getTimezoneOffset();
  fingerprint.touchPoints = window.navigator.maxTouchPoints || 0;
  fingerprint.hardwareConcurrency = navigator.hardwareConcurrency || null;
  fingerprint.language = window.navigator.language;
  fingerprint.plugins = [];
  for (let i = 0; i < navigator.plugins.length; i++) {
    const plugin = navigator.plugins[i];
    fingerprint.plugins.push({
      name: plugin.name,
      description: plugin.description,
    });
  }
  // Hash the fingerprint object to create a visitor ID
  const visitorId = hash(JSON.stringify(fingerprint));
  return { visitorId, fingerprint };
};

// Function to create a unique hash from a string using SHA-256 algorithm from CryptoJS
const hash = (str) => {
  const hash = SHA256(str).toString();
  return hash;
};

// Hash function to create a simple unique hash from a string using djb2 algorithm
// const hash = (str) => {
//   let hash = 0;
//   if (str.length === 0) {
//     return hash;
//   }
//   for (let i = 0; i < str.length; i++) {
//     const char = str.charCodeAt(i);
//     hash = ((hash << 5) - hash) + char;
//     hash = hash & hash;
//   }
//   return Math.abs(hash).toString(36);
// }

// Export the functions you want to use
export { getBrowserId, setCookie };
