import {
  Box,
  Button,
  Heading,
  Icon,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import { FaPlay } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

import BgImg from '../../../assets/Home/general/solar-home-about.png';
import { AppRoutes, GeneralRoutes } from '../../../utils/routes';

const About = () => {
  const navigate = useNavigate();

  const [isLargerThan1440] = useMediaQuery('(min-width: 1440px)');

  return (
    <>
      <Box
        h={['fit-content']}
        px={['auto', '40px']}
        pt={['40px']}
        pb={['auto', '40px']}
        bg='white'
        borderRadius={'20px'}
      >
        <Stack spacing={8} direction={isLargerThan1440 ? 'row' : 'column'}>
          <Stack spacing={6} direction='column' mx={'30px'}>
            <Text color='brand.10' fontWeight={700}>
            You never have to run out of power endless possibilities with limitless powers. 
            With altpower, power issues are a thing of the past.
            </Text>
            <Heading fontSize={'18px'} fontWeight={500} mr={'30px'}>
            Get access to constant power when you shop any of our power solutions via your mobile device or web. 
            We have a wide array of payment options to suit you (bank, card, transfer, deferred payment). 
            You can also choose a preferred option for getting their items (pickup or delivery).
            </Heading>
            <Stack spacing={8} direction='row'>
              <Box h='70px' w='20px' bg={'brand.20'} mt='25px'></Box>
              <Stack spacing={8} direction='column'>
                <Text fontSize={'14px'} mt='20px'>
                  AltPower is an e-commerce platform developed by The
                  Alternative Bank (altbank) to provide customers great shopping
                  experience and flexible payment options.
                </Text>
                <Text fontSize={'14px'}>
                  On AltPower, customers can shop using web and mobile devices;
                  choose a preferred option for getting their items (pick up or
                  delivery) as well as payment options: Pay with card (this
                  comes with a cash-back benefit), Pay with Bank (open to only
                  The Alternative Bank customers), Deferred payment by The
                  Alternative Bank.
                </Text>
                <Link to={GeneralRoutes.faq}>
                  <Button
                    w='180px'
                    h='60px'
                    variant={'solid'}
                    bg={'brand.10'}
                    color={'white'}
                    colorScheme={'green'}
                    size={'sm'}
                    rightIcon={<FaPlay ml={4} />}
                  >
                    Read More
                  </Button>
                </Link>
              </Stack>
            </Stack>
          </Stack>
          <>
            <Box borderRadius={'20px'} mx='20px'>
              <Box
                id={'bgImg'}
                w={['auto', '600px']}
                h={['300px', '450px']}
                bgImage={BgImg}
                bgPosition={'center top'}
                bgSize={'100%'}
                bgRepeat={'no-repeat'}
                position={'relative'}
                borderRadius={'20px'}
                _after={{
                  bgGradient: 'linear(to-r, green.200, pink.500)',
                }}
                style={{ position: 'relative', paddingBottom: '70px' }}
              >
                <Box
                  id={'verticalButton'}
                  bg='brand.10'
                  w='66.67%'
                  h='70px'
                  borderRadius={'20px 0px 20px 0px'}
                  position='absolute'
                  left={['-84px', '-165px']}
                  bottom={['84px', '165px']}
                  textAlign='center'
                  transform={'rotate(270deg)'}
                  _hover={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigate(AppRoutes.powerOptions);
                    window.scrollTo({
                      top: 0,
                    });
                  }}
                >
                  <Stack
                    direction={'row'}
                    spacing={[2, 8]}
                    align={'center'}
                    my={['20px', '20px']}
                    color={'white'}
                  >
                    <Box>
                      <Box h='3px' w={['20px', '80px']} bg={'white'}></Box>
                    </Box>
                    <Text textAlign={'center'} fontSize={['12px', '14px']}>
                      View All Power Solutions
                    </Text>
                    <Icon as={FaPlay} textAlign={'center'} />
                  </Stack>
                </Box>
              </Box>
            </Box>
          </>
        </Stack>
      </Box>
    </>
  );
};

export default About;
