import React from 'react';
import './index.css';
import { FaPlay } from 'react-icons/fa';
import { Box, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import {
  ABBlogo,
  SchneiderLogo,
  SiemensLogo,
  SterlingLogo,
} from '../../assets/icons/SVGicons';

const LogoSlider = ({ position }) => {
  const logos = [
    { name: 'Sterling Logo', logo: SterlingLogo },
    { name: 'SchneboxSizeer Logo', logo: SchneiderLogo },
    { name: 'ABB Logo', logo: ABBlogo },
    { name: 'Siemens Logo', logo: SiemensLogo },
    { name: 'Sterling Logo', logo: SterlingLogo },
    { name: 'SchneboxSizeer Logo', logo: SchneiderLogo },
    { name: 'ABB Logo', logo: ABBlogo },
    { name: 'Siemens Logo', logo: SiemensLogo },
    { name: 'Sterling Logo', logo: SterlingLogo },
    { name: 'SchneboxSizeer Logo', logo: SchneiderLogo },
    { name: 'ABB Logo', logo: ABBlogo },
    { name: 'Siemens Logo', logo: SiemensLogo },
  ];

  return (
    <>
      <Stack
        align={'center'}
        position={position}
        direction={'row'}
        spacing={4}
        w='100%'
        my={-1.5}
        overflow='hidden'
      >
        <HStack
          align={'center'}
          color={'brand.10'}
          w={['200px', '25%']}
          fontSize={['12px']}
          px={6}
        >
          <Text fontWeight={'700'}>Our Partners</Text>
          <FaPlay />
        </HStack>
        <Box className='logos'>
          <Box className='logos-slide'>
            {logos.map((logo, index) => (
              <Icon
                className='img'
                key={index}
                as={logo.logo}
                boxSize={14}
                mx={8}
              />
            ))}
          </Box>
          <Box className='logos-slide'>
            {logos.map((logo, index) => (
              <Icon
                className='img'
                key={index}
                as={logo.logo}
                boxSize={14}
                mx={8}
              />
            ))}
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default LogoSlider;
