import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  HStack,
  IconButton,
  Link,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import React, { Suspense, lazy } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FaPhoneAlt, FaPlay } from 'react-icons/fa';
import { BsHeadset } from 'react-icons/bs';
import { IoMdDownload } from 'react-icons/io';
import { TfiEmail } from 'react-icons/tfi';
import BgSolar from '../../../assets/Home/general/solar-panel.png';
import CustomInput from '../../../components/Input/CustomInput';
import { Form, Formik } from 'formik';
import CustomSelect from '../../../components/Select/CustomSelect';
import OnSuccess from '../../../components/Modals/OnSuccess';
import LoadingModal from '../../../components/Modals/LoadingModal';
import { useCustomMutation } from '../../../hooks/useCustomMutation';
import axiosInstance from '../../../services/api';
import { GeneralRoutes } from '../../../utils/routes';

const DownloadFormModal = lazy(() => import('./DownloadFormModal'));

const Quote = () => {
  const [onSuccessOpen, setOnSuccessOpen] = React.useState(false);
  const [onModalOpen, setOnModalOpen] = React.useState(false);

  const toast = useToast();
  const { mutate, isLoading } = useCustomMutation(axiosInstance);

  const categoryOption = [
    {
      id: 1,
      label: 'Lease to Own',
      value: 'lease to Own',
    },
    {
      id: 2,
      label: 'Pay as a Service',
      value: 'PAAS',
    },
  ];

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    // console.log('Form Submit Success', values);
    setSubmitting(true);
    const payload = {
      category: values.category,
      firstName: values.firstName,
      lastName: values.lastName,
      emailAddess: values.email,
      phoneNumber: values.phoneNumber,
      comment: values.comment,
    };

    // console.log('Quote payload', JSON.stringify(payload, null, 2));
    mutate({
      url: '/Customers/createquote',
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setSubmitting(true);
        if (data?.responseCode === '00') {
          toast({
            title: `Request sent successfully`,
            status: 'success',
          });
          setOnSuccessOpen(true);
          resetForm();
          setSubmitting(false);
        } else {
          toast({
            title: `${data?.responseMessage}`,
            status: 'error',
          });
        }
      },
      onError: (error) => {
        toast({
          title: `${error?.response?.statusText}`,
          status: 'error',
        });
      },
    });
  };

  return (
    <>
      <Suspense fallback={<LoadingModal />}>
        {onModalOpen && (
          <DownloadFormModal
            onModalOpen={onModalOpen}
            setOnModalOpen={setOnModalOpen}
          />
        )}
      </Suspense>

      <OnSuccess
        title={'Your request has been sent successfully.'}
        subtitle={
          <Text>
            You will be contacted by our business team within 24hours.
          </Text>
        }
        subWeight={'14px'}
        successBottonText={'Okay!'}
        onSuccessOpen={onSuccessOpen}
        setOnSuccessOpen={setOnSuccessOpen}
      />
      <Box
        h={['1300px', '670px']}
        px={['auto', '40px']}
        py={['auto', '40px']}
        bg='white'
        borderRadius={'20px'}
      >
        <Stack direction={['column', 'row']} spacing={12}>
          <Box
            h={['100%']}
            width={['100%', '40%']}
            bgImage={BgSolar}
            borderRadius={'20px'}
          >
            <Box
              h={['fit-content']}
              width={'100%'}
              bg='#32C36CF2'
              borderRadius={'20px'}
              px={['40px', '40px']}
              py={['40px']}
            >
              <Text color='white' opacity={'1'}>
               As a trusted distributor of solar supplies, we are committed to 
               delivering swift and expert service, ensuring you have access to 
               all the materials you require on-demand.
              </Text>
              <Stack
                direction={'row'}
                spacing={4}
                align={'center'}
                my={'40px'}
                cursor={'pointer'}
              >
                <IconButton
                  bg={'white'}
                  color={'brand.10'}
                  aria-label='Download Form'
                  icon={<IoMdDownload />}
                  borderRadius='50%'
                  size='sm'
                />
                <Text
                  color='white'
                  _hover={{
                    borderBottom: '2px solid white',
                    paddingBottom: '2px',
                  }}
                  onClick={() => setOnModalOpen(true)}
                >
                  Download offline form
                </Text>
              </Stack>
              <Stack spacing={2} mt={'60px'} mb={'40px'}>
                <HStack spacing={4} align={'center'}>
                  <IconButton
                    variant='outline'
                    color={'white'}
                    aria-label='Download Form'
                    icon={<FaPhoneAlt />}
                    borderRadius='50%'
                    size='sm'
                  />
                  <Link as='a' href='tel:02017000555'>
                    <Text color='white' fontSize={'24px'} fontWeight={600}>
                      0201 700 0555
                    </Text>
                  </Link>
                </HStack>
                <HStack spacing={4} align={'center'}>
                  <IconButton
                    variant='none'
                    color={'white'}
                    aria-label='Download Form'
                    icon={<BsHeadset />}
                    borderRadius='50%'
                    size='sm'
                  />
                  <Text color='white'>Available 24/7</Text>
                </HStack>
                <HStack spacing={4} align={'center'}>
                  <IconButton
                    variant='none'
                    color={'white'}
                    aria-label='Download Form'
                    icon={<TfiEmail />}
                    borderRadius='50%'
                    size='sm'
                  />
                  <Link as='a' href='mailto:service@altpower.ng'>
                    <Text color='white'>service@altpower.ng</Text>
                  </Link>
                </HStack>
              </Stack>
              <Link as={RouterLink} to={GeneralRoutes.faq}>
                <Button
                  w='180px'
                  h='60px'
                  variant={'solid'}
                  bg={'white'}
                  color={'brand.10'}
                  colorScheme={'green'}
                  size={'sm'}
                  rightIcon={<FaPlay />}
                  mb={'30px'}
                >
                  Read More
                </Button>
              </Link>
            </Box>
          </Box>
          <Box h={['300px']} width={['100%', '70%']} px={['30px', 'auto']}>
            <Text fontSize={'24px'} fontWeight={600}>
              Request a Quote
            </Text>
            <Text>
              We make sure that you receive your products in the best quality.
            </Text>
            <>
              <Formik
                initialValues={{
                  category: '',
                  firstName: '',
                  lastName: '',
                  email: '',
                  phoneNumber: '',
                  comment: '',
                }}
                onSubmit={handleSubmit}
                // validationSchema={QuoteSchema}
              >
                {({
                  isSubmitting,
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                }) => (
                  <Form>
                    <FormControl>
                      <Box w={['100%']}>
                        <Box w={'100%'} mt={['23px']}>
                          <CustomSelect
                            title='Category'
                            onSelectChange={handleChange}
                            onSelectBlur={handleBlur}
                            selectValue={values.category}
                            selectName='category'
                            selectId='category'
                            options={categoryOption}
                            placeholder={'-- Select Category --'}
                            error={
                              errors.category && touched.category ? (
                                <FormHelperText
                                  mt={1}
                                  fontSize={'11px'}
                                  color='#F10000'
                                >
                                  {errors.category}
                                </FormHelperText>
                              ) : null
                            }
                          />
                        </Box>

                        <HStack spacing={[4, 8]}>
                          <Box width={'50%'}>
                            <CustomInput
                              title='Enter First Name'
                              inputChange={handleChange}
                              inputBlur={handleBlur}
                              inputValue={values.firstName}
                              inputName='firstName'
                              InputId='firstName'
                              border={
                                errors.firstName && touched.firstName
                                  ? '1px solid #F10000 !important'
                                  : '1px solid #959595 !important'
                              }
                              error={
                                errors.firstName && touched.firstName ? (
                                  <FormHelperText
                                    mt={1}
                                    fontSize={'11px'}
                                    color='#F10000'
                                  >
                                    {errors.firstName}
                                  </FormHelperText>
                                ) : null
                              }
                            />
                          </Box>
                          <Box width={'50%'}>
                            <CustomInput
                              title='Enter Last Name'
                              inputChange={handleChange}
                              inputBlur={handleBlur}
                              inputValue={values.lastName}
                              inputName='lastName'
                              InputId='lastName'
                              border={
                                errors.lastName && touched.lastName
                                  ? '1px solid #F10000 !important'
                                  : '1px solid #959595 !important'
                              }
                              error={
                                errors.lastName && touched.lastName ? (
                                  <FormHelperText
                                    mt={1}
                                    fontSize={'11px'}
                                    color='#F10000'
                                  >
                                    {errors.lastName}
                                  </FormHelperText>
                                ) : null
                              }
                            />
                          </Box>
                        </HStack>

                        <HStack spacing={[4, 8]}>
                          <Box width={'50%'}>
                            <CustomInput
                              title='Enter Email Address'
                              inputChange={handleChange}
                              inputBlur={handleBlur}
                              inputValue={values.email}
                              inputName='email'
                              InputId='email'
                              border={
                                errors.email && touched.email
                                  ? '1px solid #F10000 !important'
                                  : '1px solid #959595 !important'
                              }
                              error={
                                errors.email && touched.email ? (
                                  <FormHelperText
                                    mt={1}
                                    fontSize={'11px'}
                                    color='#F10000'
                                  >
                                    {errors.email}
                                  </FormHelperText>
                                ) : null
                              }
                            />
                          </Box>
                          <Box width={'50%'}>
                            <CustomInput
                              title='Enter Phone Number'
                              inputChange={handleChange}
                              inputBlur={handleBlur}
                              inputValue={values.phoneNumber}
                              inputName='phoneNumber'
                              InputId='phoneNumber'
                              border={
                                errors.phoneNumber && touched.phoneNumber
                                  ? '1px solid #F10000 !important'
                                  : '1px solid #959595 !important'
                              }
                              error={
                                errors.phoneNumber && touched.phoneNumber ? (
                                  <FormHelperText
                                    mt={1}
                                    fontSize={'11px'}
                                    color='#F10000'
                                  >
                                    {errors.phoneNumber}
                                  </FormHelperText>
                                ) : null
                              }
                            />
                          </Box>
                        </HStack>
                        <Box
                          h={'100px'}
                          mb={['15px !important', '30px !important']}
                        >
                          <Text
                            mb={['8px']}
                            lineHeight={['16px', '17px']}
                            fontSize={['12px', '14px']}
                            fontWeight={'500'}
                            color={'black'}
                            fontFamily={'Montserrat'}
                          >
                            Comment
                          </Text>
                          <Textarea
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.comment}
                            name='comment'
                            id='comment'
                            border={
                              errors.comment && touched.comment
                                ? '1px solid #F10000 !important'
                                : '1px solid #d9d9d9 !important'
                            }
                            _focus={{
                              borderColor: 'none !important',
                              boxShadow: 'none',
                            }}
                          />
                          {errors.comment && touched.comment ? (
                            <FormHelperText
                              mt={1}
                              fontSize={'11px'}
                              color='#F10000'
                            >
                              {errors.comment}
                            </FormHelperText>
                          ) : null}
                        </Box>

                        <Box mt={['30px']}>
                          <Button
                            bg='brand.10'
                            colorScheme={'green'}
                            w={['100%']}
                            fontWeight={['600']}
                            h='48px'
                            fontSize='16px'
                            color='white'
                            type='submit'
                            disabled={isSubmitting}
                            isLoading={isLoading}
                            loadingText={'Submitting...'}
                          >
                            Submit Request
                          </Button>
                        </Box>
                      </Box>
                    </FormControl>
                  </Form>
                )}
              </Formik>
            </>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default Quote;
