import React from 'react';
import { Text, Box, Stack, Image, HStack } from '@chakra-ui/react';

const CustomDropdown = React.memo(({ icon, title, options, width, top }) => {
  const [open, setOpen] = React.useState(false);
  const [optionValue, setOptionValue] = React.useState('');

  const dropdownRef = React.useRef(null);

  const handleClick = (option, id) => {
    setOptionValue(option);
    setOpen(false);
  };

  React.useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dropdownRef]);

  return (
    <>
      <HStack
        cursor={'pointer'}
        onClick={() => setOpen(!open)}
        position={'relative'}
        align={'center'}
      >
        {icon || null}
        <Text>{title || optionValue}</Text>
      </HStack>
      <Box
        w={width || '180px'}
        p={4}
        bg={open ? 'white' : null}
        position={'absolute'}
        top={top || ['40px', '65px']}
        zIndex='2'
      >
        {open && (
          <Box>
            {options.map((option) => (
              <Stack
                direction={'column'}
                key={option.id}
                onClick={() => handleClick(option.value, option.id)}
                fontSize={['12px', '14px']}
              >
                <Box onClick={option.route} cursor={'pointer'}>
                  <HStack spacing={4}>
                    <Image src={option.icon || null} />
                    <Text>{option.value}</Text>
                  </HStack>
                </Box>
                <Box
                  w={'100%'}
                  h={'1px'}
                  borderTop={'0.5px solid #828282'}
                  py={1}
                ></Box>
              </Stack>
            ))}
          </Box>
        )}
      </Box>
    </>
  );
});

export default CustomDropdown;
