import { Box, HStack, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import SolarPanels from '../../../assets/Home/general/panel_circle.png';
import TestimonialImg1 from '../../../assets/Home/dummy_pic/customer1.png';
import TestimonialImg2 from '../../../assets/Home/dummy_pic/customer2.png';
import TestimonialImg3 from '../../../assets/Home/dummy_pic/customer3.png';

const testimonialData = [
  {
    id: 1,
    name: 'Akpan Okot',
    title: 'Customer',
    image: TestimonialImg1,
    comment:
      'I started using AltPower because my generator engine packed up and I was tired of it all and being stressed about fuel prices. The difference is clear oh! I have not used a generator a day since and all i do is pay the monthly fee and i have peace! I love AltPower as a service',
  },
  {
    id: 2,
    name: 'Joy Eze',
    title: 'Customer',
    image: TestimonialImg2,
    comment: `Altpower power as a service solution impressed me on multiple levels. Fast setup, affordable price, amazing customer service... I will refer everyone I know. You won't regret it.`,
  },
  {
    id: 3,
    name: 'ABZ Solar Venturs',
    title: 'Vendor',
    image: TestimonialImg3,
    comment:
      'We have no regrets, our sales and customer base tripled in less that 3 months thanks to Altpower',
  },
];

const Testimonials = () => {
  const [showComment, setShowComment] = React.useState(1);

  return (
    <>
      <Box
        h={['fit-content']}
        px={['30px', '40px']}
        py={['40px']}
        mb={['', '70px']}
      >
        <Stack direction={['column', 'row']} spacing={12} align={['center']}>
          <Box h={['fit-content']} width={['100%', '40%']}>
            <Image
              src={SolarPanels}
              alt='Solar Panels'
              w={'300px'}
              h={'auto'}
            />
          </Box>
          <Box
            h={['fit-content']}
            width={['100%', '60%']}
            borderRadius={'20px'}
          >
            <Text fontSize={['14px', '16px']} fontWeight={700} mb={['30px']}>
              What Our Customers Are Saying
            </Text>
            <>
              <Box height={'100px'}>
                {showComment === 1 ? (
                  <Text fontSize={['12px', '14px']}>
                    {testimonialData.find((item) => item.id === 1).comment}
                  </Text>
                ) : null}
                {showComment === 2 ? (
                  <Text fontSize={['12px', '14px']}>
                    {testimonialData.find((item) => item.id === 2).comment}
                  </Text>
                ) : null}
                {showComment === 3 ? (
                  <Text fontSize={['12px', '14px']}>
                    {testimonialData.find((item) => item.id === 3).comment}
                  </Text>
                ) : null}
              </Box>
              <HStack spacing={[4, 8]} mt={['10px']}>
                {testimonialData?.map((item) => (
                  <HStack
                    key={item.id}
                    onClick={() => setShowComment(item.id)}
                    cursor={'pointer'}
                  >
                    <Image
                      src={item.image}
                      alt={item.name}
                      w={['30px', '40px']}
                      h='auto'
                      borderRadius={'50%'}
                      border={
                        showComment === item.id ? '2px solid #32C36C' : null
                      }
                      p={'2px'}
                    />

                    <Stack>
                      <Text fontSize={['12px', '14px']}>{item.name}</Text>
                      <Text fontSize={['12px', '14px']} mt={'0px !important'}>
                        {item.title}
                      </Text>
                    </Stack>
                  </HStack>
                ))}
              </HStack>
            </>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default Testimonials;
