import { refreshTokenAsync } from '../redux/reducers/userSlice';
import { getAccessToken, getRefreshToken } from './auth';

export const tokenRefreshMiddleware = (store) => (next) => (action) => {
  const { dispatch } = store;

  if (store.getState().user.isAuthenticated) {
    const refreshTokenData = getRefreshToken();
    const accessToken = getAccessToken();

    if (refreshTokenData && accessToken) {
      const expirationTime = refreshTokenData.expirationTime;
      const currentTime = new Date().getTime();

      if (currentTime >= expirationTime - 2 * 60 * 1000) {
        dispatch(refreshTokenAsync(refreshTokenData.refreshToken));
      }
    }
  }

  return next(action);
};
