
export const AppRoutes = {
  home: '/',
  powerOptions: '/power_options',
  paasRegister: '/power_options/paas/:planName',
  vendors: 'vendors',
  help: 'help',
  account: 'account',
  cart: 'cart',
  signup: '/signup',
  signin: '/signin',
  verifyEmail: '/verifyemail',
  forgotPassword: '/signin/forgotpassword',
  resetPassword: '/signin/resetpassword',
  products: 'products',
  // singleProduct: 'item/:productId',
  singleProduct: 'products/category/:category/item/:productId',
  productCategory: 'products/category/:category',
  productSubcategory: 'products/category/:category/:subCategory',
  notfound: '*',
};


export const CategoryRoutes = {
  gen: 'generators_and_accessories',
  inverters: 'inverters',
  monitors: 'monitors_and_metering',
  batteries: 'batteries',
  solar: 'solar',
  bundles: 'bundles',
  ups: 'ups',
  stabilizers: 'stabilizers',
};

export const AccountRoutes = {
  dashboard: '/account/dashboard',
  orderHistory: '/account/order_history',
  saves: '/account/saves',
  altFin: '/account/altfinance',
  addBook: '/account/address_book',
  settings: '/account/settings',
};

export const CheckoutRoutes = {
  shipping: '/checkout/shipping',
  newShipping: '/checkout/add_shipping_address',
  shippingCo: '/checkout/shipping_company',
  orderSummary: '/checkout/order_summary',
  payLater: '/checkout/paylater',
  requestStatement: '/checkout/request_bank_statement',
  creditSummary: '/checkout/credit_summary',
  cashCollateral: '/checkout/cash_collateral',
  payNowRedirect: '/checkout/paynow/redirect',
  payLaterRedirect: '/checkout/paylater/redirect',
};

export const GeneralRoutes = {
  about: '/about',
  terms: '/terms_&_conditions',
  deliveryInfo: '/delivery_information',
  paymentOptions: '/payment_options',
  paas: '/power_options',
  privacyPolicy: '/privacy_policy',
  returnPolicy: '/return_policy',
  faq: '/faq',
};
