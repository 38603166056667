import PBKDF2 from 'crypto-js/pbkdf2';
import Utf8 from 'crypto-js/enc-utf8';
import AES from 'crypto-js/aes';

const PassPhrase = String(process.env.REACT_APP_PassPhrase);
const SaltValue = String(process.env.REACT_APP_SaltValue);
const InitVector = String(process.env.REACT_APP_InitVector);
const PasswordIterations = Number.parseInt(
  String(process.env.REACT_APP_PasswordIterations)
);
const Blocksize = Number.parseInt(String(process.env.REACT_APP_Blocksize));

export const encryptRequest = async (request) => {
  var key = PBKDF2(PassPhrase, Utf8.parse(SaltValue), {
    keySize: 256 / Blocksize,
    iterations: PasswordIterations,
  });
  var parsedIV = Utf8.parse(InitVector);
  // Encrypt
  var encrypted = AES.encrypt(JSON.stringify(request), key, {
    iv: parsedIV,
  }).toString();

  return encrypted;
};

export const decryptResponse = async (response) => {
  var key = PBKDF2(PassPhrase, Utf8.parse(SaltValue), {
    keySize: 256 / Blocksize,
    iterations: PasswordIterations,
  });
  var parsedIV = Utf8.parse(InitVector);
  
  // Decrypt
  var bytes = AES.decrypt(response, key, { iv: parsedIV });
  var decryptedResponse = bytes.toString(Utf8);
  try {
    return JSON.parse(decryptedResponse);
  } catch (error) {
    return decryptedResponse;
  }
};
