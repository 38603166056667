import React from 'react';
import {
  Input,
  Text,
  Box,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';

const CustomInput = ({
  title = 'Title',
  inputChange,
  inputIcon,
  inputBlur,
  inputValue,
  inputName,
  InputId,
  type,
  placeHolder,
  disabled,
  inputHeight,
  error,
  textColor = '',
  fontSize,
  textStyles = {},
  fontFamily,
  fontWeight,
  boxStyles,
}) => {
  return (
    <>
      <Box height={'90px'}>
        <Text
          mb={['8px']}
          lineHeight={['16px', '17px']}
          fontSize={fontSize || ['12px', '14px']}
          fontWeight={fontWeight || '500'}
          color={textColor || 'black'}
          fontFamily={fontFamily || 'Montserat'}
          {...textStyles}
        >
          {title}
        </Text>
        <Box
          display={'flex'}
          flexDirection={['row']}
          alignItems={['center']}
          {...boxStyles}
          border={
            error
              ? '1px solid #F10000 !important'
              : '1px solid #d9d9d9 !important'
          }
          style={{
            width: '100%',
            borderRadius: '5px',
          }}
        >
          <InputGroup>
            <InputLeftElement
              pointerEvents='none'
              children={inputIcon || null}
            />
            <Input
              bg='white'
              border={'none !important'}
              onChange={inputChange}
              onBlur={inputBlur}
              value={inputValue}
              name={inputName}
              id={InputId}
              type={type || 'text'}
              placeholder={placeHolder || ''}
              disabled={disabled || false}
              fontSize={fontSize || ['14px']}
              style={{
                width: '100%',
                border: '1px solid #828282',
                borderRadius: '5px',
              }}
              h={inputHeight || ['40px']}
              _focus={{
                borderColor: 'none !important',
                boxShadow: 'none',
              }}
            />
          </InputGroup>
        </Box>
        {error}
      </Box>
    </>
  );
};

export default CustomInput;
