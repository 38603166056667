import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { Suspense, lazy } from 'react';
import LogoSlider from '../../components/Slider';
import About from './component/About';
import Categories from './component/Categories';
import InverterBox from './component/InverterBox';
import HeroImg from '../../assets/Home/general/solar-home-bg.png';
import Quote from './component/Quote';
import Testimonials from './component/Testimonials';
import MiniFAQ from './component/MiniFAQ';
import Footer from '../../components/Navigation/Footer';
import { FaPlay } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../utils/routes';
import LoadingModal from '../../components/Modals/LoadingModal';

const VideoPopup = lazy(() => import('../../components/Video'));

const Home = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box w={'100%'} h={'20px'}></Box>
      <Box
        h={['280px', '450px']}
        bg='#F4F4F4'
        position={'relative'}
        borderRadius={'20px'}
        mx={['auto', 24]}
      >
        <Box
          h={['180px', '90%']}
          bgImage={HeroImg}
          bgPosition={['center', 'center top']}
          bgSize={['cover', '100%']}
          bgRepeat={'no-repeat'}
          borderRadius={'20px'}
        >
          <Box
            h={['100%']}
            px={['30px']}
            py={['20px', '80px']}
            bgGradient={'linear(to-r, black, transparent)'}
            borderRadius={'20px'}
          >
            <Text
              color={'brand.10'}
              fontWeight={600}
              mb={[0, 2]}
              fontSize={['14px', '16px']}
            >
              Take control of the power situation in your home or business.
            </Text>
            <Text
              w={['90%', '400px']}
              mb={[4, 6]}
              color={'white'}
              fontSize={['20px', '30px']}
              fontWeight={600}
              lineHeight={1.2}
            >
              Constant Power, now in your hands!
            </Text>
            <HStack spacing={8} w={'100%'}>
              <Link to={AppRoutes.signup}>
                <Button
                  h={['40px', '60px']}
                  w={['130px', '180px']}
                  variant={'solid'}
                  bg={'brand.10'}
                  color={'white'}
                  colorScheme='green'
                  fontSize={['14px', '16px']}
                  size={'sm'}
                >
                  Get Started
                  <Icon as={FaPlay} boxSize={3} ml={[4, 8]} />
                </Button>
              </Link>
              <>
                <HStack color={'white'} cursor={'pointer'} onClick={onOpen}>
                  <Center
                    w={['40px', '50px']}
                    h={['40px', '50px']}
                    border={'1px solid white'}
                    borderRadius={'50%'}
                  >
                    <Center
                      w={['30px', '40px']}
                      h={['30px', '40px']}
                      bg={'white'}
                      borderRadius={'50%'}
                    >
                      <Icon color={'brand.10'} as={FaPlay} boxSize={3} />
                    </Center>
                  </Center>
                  <Text fontSize={['12px', '14px']}>Watch Explainer Video</Text>
                </HStack>
                <Suspense fallback={<LoadingModal />}>
                  {isOpen && <VideoPopup isOpen={isOpen} onClose={onClose} />}
                </Suspense>
              </>
            </HStack>
          </Box>
        </Box>
        <LogoSlider position={['none', 'absolute']} />
        <InverterBox position={['none', 'absolute']} />
      </Box>
      <Box mx={['auto', 24]} mt={['65%', '80px']}>
        <Categories />
      </Box>
      <Box mx={['auto', 24]} mt={['50px', '80px']}>
        <About />
      </Box>
      <Box mx={['auto', 24]} mt={['50px', '80px']}>
        <Quote />
      </Box>
      <Box mx={['auto', 24]} mt={['50px', '80px']}>
        <Testimonials />
      </Box>
      <Box mx={['auto']} mt={['30px', '80px']}>
        <MiniFAQ />
      </Box>
      <Footer />
    </>
  );
};

export default Home;
