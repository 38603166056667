import {
  Box,
  Center,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';

const LoadingModal = ({ isOpen }) => {
  return (
    <Modal blockScrollOnMount={true} isOpen={isOpen} isCentered>
      <ModalOverlay
        css={{
          background: 'rgba(0, 0, 0, 0.85)',
        }}
      />
      <ModalContent
        backgroundColor='transparent'
        boxShadow='none'
      >
        <Box
          position='fixed'
          top='0'
          left='0'
          width='100vw'
          height='100vh'
          zIndex='1000'
          display='flex'
          alignItems='center'
          justifyContent='center'
          background='rgba(0, 0, 0, 0.6)'
        >
          <Center>
            <Spinner
              size={'xl'}
              thickness='10px'
              color='#32C36C'
              width='200px'
              height='200px'
            />
          </Center>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default LoadingModal;
