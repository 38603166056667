// import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { setCartItems, setTotalCartAmount } from '../redux/reducers/cartSlice';
import axiosInstance from '../services/api';
import { setWishlist } from '../redux/reducers/userSlice';
import { setTotalQuantity } from '../redux/reducers/checkoutSlice';
import { setLocal } from './auth';

const fetchCartItems = async (CustomerId, UserBrowserId) => {
  if (CustomerId || UserBrowserId) {
    const params = { CustomerId, UserBrowserId };
    const response = await axiosInstance.get(
      '/Cart/getAllCustomerProductsInCart',
      { params }
    );
    return response.data;
  } else {
    // Handle the case when both CustomerId and UserBrowserId are falsy
    return [];
  }
};

const sumCartAmount = (cartItems) => {
  let totalAmount = 0;
  if (Array.isArray(cartItems) && cartItems.length > 0) {
    for (const cartItem of cartItems) {
      totalAmount += cartItem.productTotalAmount;
    }
  }
  return totalAmount;
};

const sumCartQty = (cartItems) => {
  let totalQty = 0;
  if (Array.isArray(cartItems) && cartItems.length > 0) {
    for (const cartItem of cartItems) {
      totalQty += cartItem.productQuantity;
    }
  }
  return totalQty;
};

export const useFetchCartItems = (CustomerId, UserBrowserId) => {
  const dispatch = useDispatch();

  const { data, refetch, isLoading } = useQuery(
    ['cartItems', CustomerId, UserBrowserId],
    () => fetchCartItems(CustomerId, UserBrowserId),
    {
      refetchOnMount: true,
      enabled: document.hasFocus(), // Disable initial fetch if window is not focused
      staleTime: Infinity, // Disable refetch on window focus
      manual: true, // Enable manual triggering
      onSuccess: (data) => {
        dispatch(setCartItems(data?.data));
        setLocal('cart', data?.data);
        const totalCartAmount = sumCartAmount(data?.data);
        dispatch(setTotalCartAmount(totalCartAmount));
        setLocal('totalAmt', totalCartAmount)
        const totalCartQty = sumCartQty(data?.data);
        dispatch(setTotalQuantity(totalCartQty));
        setLocal('totalQty', totalCartQty)
      },
    }
  );

  return {
    cartItems: data,
    isLoadingCart: isLoading,
    refetchCartItems: refetch,
  };
};

const fetchWishlistItems = async (CustomerId, UserBrowserId) => {
  if (CustomerId || UserBrowserId) {
    const params = { CustomerId, UserBrowserId };
    const response = await axiosInstance.get(
      '/WishListProduct/getCustomerWishListProduct',
      { params }
    );
    return response.data;
  } else {
    // Handle the case when both CustomerId and UserBrowserId are falsy
    return [];
  }
};

export const useGetWishlistItems = (CustomerId, UserBrowserId) => {
  const dispatch = useDispatch();

  const { data, refetch, isLoading } = useQuery(
    ['wishlistItems', CustomerId, UserBrowserId],
    () => fetchWishlistItems(CustomerId, UserBrowserId),
    {
      refetchOnMount: true,
      enabled: document.hasFocus(),
      staleTime: Infinity,
      manual: true,
      onSuccess: (data) => {
        dispatch(setWishlist(data?.data));
        setLocal('wishlist', data?.data)
      },
    }
  );

  return {
    wishlistItems: data,
    isLoadingWishlist: isLoading,
    refetchWishlistItems: refetch,
  };
};
