import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import React from 'react';
import { FaCaretDown, FaPlay } from 'react-icons/fa';

const Accordions = ({ accordionOptions }) => {
  return (
    <Box h={['fit-content']}>
      <Accordion color='inherent'>
        {accordionOptions.map((item) => (
          <AccordionItem key={item.id}>
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton fontSize={'14px'}>
                    {isExpanded ? (
                      <Box
                        w='30px'
                        h='30px'
                        p='4.6px'
                        borderRadius={'50px'}
                        bg={'black'}
                        color={'white'}
                      >
                        <FaCaretDown fontSize='20px' />
                      </Box>
                    ) : (
                      <Box
                        w='30px'
                        h='30px'
                        p='10px'
                        borderRadius={'50px'}
                        bg={'white'}
                        color={'black'}
                      >
                        <FaPlay fontSize='12px' bg='white' color='black' />
                      </Box>
                    )}
                    <Box
                      as='span'
                      ml={'30px'}
                      textAlign='left'
                      fontWeight={800}
                    >
                      {item.title}
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} pl='80px' fontSize={['12px', '14px']}>
                  {item.desc}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default Accordions;
