import { createSlice } from '@reduxjs/toolkit';

const productSlice = createSlice({
  name: 'products',
  initialState: {
    data: [],
    loading: false,
    error: null,
    categoryName: '',
    categories: [],
    subCategories: [],
    selectedCategory: null,
    filter: {
      priceRange: [0, 1000000],
      vendors: [],
    },
    searchText: '',
    sortField: '',
    sortDirection: '',
    filteredData: [],
  },
  reducers: {
    setProductData: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      // state.filteredData = action.payload; // Set the filtered data to the initial data
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    getCategories: (state, action) => {
      state.categories = action.payload;
    },
    getSubCategories: (state, action) => {
      state.subCategories = action.payload;
    },
    setFilteredData: (state, action) => {
      state.filteredData = action.payload;
      state.loading = false;
      // state.filteredData = action.payload; // Set the filtered data to the initial data
    },
    setCategoryName: (state, action) => {
      state.categoryName = action.payload;
    },
    setCategoryId: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setPriceRange: (state, action) => {
      // console.log('price range:', action.payload);
      state.filter.priceRange = action.payload;
    },
    setVendors: (state, action) => {
      state.filter.vendors = action.payload;
    },
    setSearchText(state, action) {
      // console.log('SearchText:', action.payload);
      state.searchText = action.payload;
    },
    setSortField(state, action) {
      state.sortField = action.payload;
    },
    setSortDirection(state, action) {
      state.sortDirection = action.payload;
    },
    setClearFilters: (state, action) => {
      state.searchText = '';
      state.sortField = '';
      state.sortDirection = '';
      state.filter.priceRange = [0, 1000000];
      state.filter.vendors = [];
      state.filteredData = state.data;
    },
  },
});

export const {
  setProductData,
  setLoading,
  setError,
  getCategories,
  getSubCategories,
  setCategoryName,
  setCategoryId,
  setPriceRange,
  setVendors,
  setSearchText,
  setSortField,
  setSortDirection,
  setFilteredData,
  setClearFilters,
} = productSlice.actions;

export default productSlice.reducer;
