import { Box, Icon, Text, HStack, Center, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

const NotFound = () => {
  return (
    <Center w={'100%'}>
      <Box width={['90%', '30%']} h={'86vh'}>
        <Text fontSize={'40px'} fontWeight={600} mt={24}>
          404 Error
        </Text>

        <Text fontSize={'32px'} mt={0} mb={8}>
          Page not found...
        </Text>
        <Link
          as={RouterLink}
          to='/'
          fontSize={'14px'}
          _hover={{ textDecoration: 'none !important' }}
        >
          <HStack spacing={2} mt={6}>
            <Icon
              as={MdArrowBack}
              border={'1px solid black'}
              _hover={{ color: 'brand.10', border: '1px solid #32C36C' }}
              borderRadius={'50%'}
              boxSize={6}
            />
            <Text _hover={{ color: 'brand.10' }}>Go back to homepage</Text>
          </HStack>
        </Link>
      </Box>
    </Center>
  );
};

export default NotFound;
