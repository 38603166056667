import { Box, Stack, Text } from "@chakra-ui/react";
import React from "react";

import BuildingImg from "../../../assets/Home/general/bg-buildings.png";
import Accordions from "../../../components/Accordion";

const MiniFAQ = () => {
  const accordionOptions = [
    {
      id: 1,
      title: "What is Altpower?",
      desc: "AltPower is an e-commerce platform developed by The Alternative Bank to provide customers with great shopping experiences for solar and energy products while having flexible payment options.",
    },
    {
      id: 2,
      title: "How it works?",
      desc: (
        <>
          <Text>
            1. Search via our categories OR fill and return the AltPower
            Application Form.
            <br />
            2. Attach a 6-month bank statement from any bank (as an individual)
            OR 1-Year bank statement from any bank along with a CAC document (as
            a business).
            <br />
            3. Attach a vendor's invoice.
            <br />
            Also, the customer must be aware that they are required to pay a 20%
            cash collateral from the cost of the asset as the outstanding 80% is
            to be funded by AltPower.
          </Text>
        </>
      ),
    },
    {
      id: 3,
      title: "What is the duration of payment for a product?",
      desc: "Customer can pay 32.8% p.a rate spread over a maximum of 48 months (about 4 years).   ",
    },

    {
      id: 4,
      title: "Can I make Outright payment?",
      desc: "Yes, customers can make outright payment by paying online using your bank card.",
    },
    {
      id: 5,
      title: "Do I have to commit to a Contract?",
      desc: "Yes, you are committed to an offer letter immediately you agree for an application for a product purchase to be approved. Until repayment is completely made, the contract still runs.",
    },
    {
      id: 6,
      title: "What Payment Methods are Available?",
      desc: "On AltPower, customers can shop using web and mobile devices; choose a preferred option for getting their items (pick up or delivery) as well as payment options: Pay with card, Pay with Bank (open to only The Alternative Bank customers), Deferred payment by The Alternative Bank.",
    },
  ];
  return (
    <Box h={["fit-content"]} w={["auto"]} bgImage={BuildingImg}>
      <Box
        h={["fit-content"]}
        px={["auto", "20px"]}
        py={["80px"]}
        bgGradient={"linear(to-r, #32C36C7F, #32C36CF2)"}
      >
        <Stack direction={["column", "row"]}>
          <Box
            h={["fit-content"]}
            width={["100%", "40%"]}
            borderRadius={"20px"}
            px={"80px"}
          >
            <Text fontSize={"14px"} fontWeight={800}>
              Frequently asked questions
            </Text>
            <Text fontSize={"34px"} fontWeight={800} color={"white"}>
              Would you like us to answer the questions bothering your mind?
            </Text>
          </Box>
          <Box
            h={["fit-content"]}
            width={["100%", "40%"]}
            borderRadius={"20px"}
            pl={"50px"}
            color={"white"}
          >
            <Text fontSize={"14px"} mb={"40px"}>
              Say goodbye to spending a large chunk of your hard-earned money to
              get power, spending long hours at gas station queues to buy petrol
              for the generator and say hello to 24hr power supply that allows
              you pay monthly, at your own convenience.
            </Text>
            <Box>
              <Accordions accordionOptions={accordionOptions} />
            </Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default MiniFAQ;
