import { useMutation } from 'react-query';
import { getAccessToken, getLocal } from './auth';
import axiosInstance from '../services/api';
import { router } from '../utils/router';
import { AppRoutes } from '../utils/routes';

export const useCustomMutation = () => {
  const customMutation = ({ url, data, method, onSuccess, onError }) => {
    const handleSuccess = (response) => {
      if (onSuccess) {
        onSuccess(response.data);
      }
      return response.data;
    };

    const handleError = (error) => {
      if (onError) {
        console.log('ERROR: ');
        onError(error.response?.data?.message || 'An error occurred');
      }
      // Check if the error is related to authentication
      if (error.response && error.response.status === 401) {
        router.navigate(AppRoutes.signin);
      }
      // throw new Error(error);
      console.log('ERROR2: ', error);
      throw error || 'An error occurred';
    };

    const user = getLocal('user');

    const accessToken = user?.access_token || getAccessToken();
    if (accessToken) {
      axiosInstance.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${accessToken}`;
    }

    switch (method) {
      case 'GET':
        return axiosInstance.get(url).then(handleSuccess).catch(handleError);
      case 'POST':
        return axiosInstance
          .post(url, data)
          .then(handleSuccess)
          .catch(handleError);
      case 'PUT':
        return axiosInstance
          .put(url, data)
          .then(handleSuccess)
          .catch(handleError);
      case 'PATCH':
        return axiosInstance
          .patch(url, data)
          .then(handleSuccess)
          .catch(handleError);
      case 'DELETE':
        return axiosInstance.delete(url).then(handleSuccess).catch(handleError);
      default:
        throw new Error(`Invalid request type: ${method}`);
    }
  };

  return useMutation(customMutation);
};
