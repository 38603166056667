import {
  Button,
  Center,
  HStack,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BsPlayCircleFill } from 'react-icons/bs';
import SuccessGif from '../../assets/icons/success.gif';

const OnSuccess = ({
  onSuccessOpen,
  setOnSuccessOpen,
  title,
  subtitle,
  subWeight,
  successBottonText,
  route,
}) => {
  const navigate = useNavigate();
  return (
    <Modal
      isCentered
      closeOnOverlayClick={false}
      isOpen={onSuccessOpen}
      onClose={() => setOnSuccessOpen(false)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Center>
            <Image src={SuccessGif} alt={'Success'} w={'60%'} h={'auto'} />
            {/* <Icon
              as={BsCheck2}
              p={4}
              mt={20}
              bg={'green'}
              color='white'
              boxSize={20}
              borderRadius={'50%'}
            /> */}
          </Center>
          <Text fontSize={'20px'} fontWeight={600} textAlign={'center'}>
            {title}
          </Text>
          <Text
            fontSize={subWeight || '20px'}
            px={12}
            pt={4}
            mb={8}
            textAlign={'center'}
            lineHeight={1.25}
          >
            {subtitle}
          </Text>
        </ModalBody>

        <ModalFooter px={20}>
          <Button
            w={'100%'}
            mb={12}
            colorScheme='green'
            borderRadius={'5px'}
            fontSize={'14px'}
            fontWeight={400}
            mr={3}
            onClick={() => {
              setOnSuccessOpen(false);
              navigate(route);
            }}
          >
            <HStack spacing={24}>
              <Text>{successBottonText || ''}</Text>
              <Icon as={BsPlayCircleFill} color={'white'} />
            </HStack>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OnSuccess;
