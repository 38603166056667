import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import {
  clearLocal,
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setLocal,
  setRefreshToken,
} from '../hooks/auth';
import { decryptResponse, encryptRequest } from './crypto';
import { router } from '../utils/router';
import { AppRoutes } from '../utils/routes';

const baseURL = process.env.REACT_APP_BaseURL;

const axiosInstance = axios.create({
  baseURL,
  headers: {
    accept: 'text/plain',
    'Content-Type': 'application/json',
    'x-clientid': String(process.env.REACT_APP_ClientId),
  },
});

export const refreshTokenLogic = async (userId, refreshToken) => {
  let token = getAccessToken();
  if (token === undefined || token === 'undefined' || !token) {
    router.navigate(AppRoutes.signin);
  }
  try {
    const response = await axiosInstance.post('/Customers/refreshtoken', {
      userId,
      refreshToken,
    });
    // console.log('RT Response', response);
    if (response?.data?.responseCode === '00' || response?.data?.isSuccessful) {
      const data = response?.data?.data;
      setAccessToken(data?.accessToken);
      setRefreshToken(data?.refreshToken, userId);
      return data?.accessToken;
    } else {
      router.navigate(AppRoutes.signin);
      return null;
    }
  } catch (error) {
    // Handle error refreshing token
    router.navigate(AppRoutes.signin);
    return null;
  }
};

const configureAxios = async (config) => {
  config.transformRequest = [(data) => data];

  if (config.method !== 'GET') {
    try {
      const encryptedData = await encryptRequest(config.data);
      // console.log('Encrypted Data', config.data)
      config.data = encryptedData;
    } catch (error) {
      // Handle encryption error
    }
  }

  return config;
};

const checkAndRefreshToken = async () => {
  const accessToken = getAccessToken() ?? null;
  const refreshTokenData = getRefreshToken() ?? {};

  if (!accessToken || !refreshTokenData.refreshToken) {
    return null;
  }

  const decodedToken = jwtDecode(accessToken);
  if (decodedToken.exp * 1000 > new Date().getTime()) {
    return accessToken;
  }

  const newAccessToken = await refreshTokenLogic(
    refreshTokenData.customerId,
    refreshTokenData.refreshToken
  );

  return newAccessToken;
};

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = await checkAndRefreshToken();

    // console.log('access Tok', accessToken)

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return configureAxios(config);
  },
  (error) => {
    console.log('Req Err Status ', error);
    if (error.response && error.response.status === 401) {
      setLocal('auth', false);
      clearLocal('user');
      router.navigate(AppRoutes.signin);
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    if (response.data) {
      try {
        const decryptedData = await decryptResponse(response.data);
        // console.log('Decrypted Data:', decryptedData);
        response.data = decryptedData;
      } catch (error) {
        // Handle decryption error
        console.error('Decryption Error:', error);
      }
    }
    return response;
  },
  (error) => {
    console.log('Res Err Status ', error?.message);
    if (error.response && error.response.status === 401) {
      setLocal('auth', false);
      clearLocal('user');
      router.navigate(AppRoutes.signin);
    }
    return Promise.reject(error?.message);
  }
);

export default axiosInstance;
