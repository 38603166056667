import React from "react";
import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { router } from "./utils/router";
import {
  clearAccessToken,
  clearLocal,
  clearRefreshToken,
  getLocal,
  setAccessToken,
  setLocal,
  setRefreshToken,
} from "./hooks/auth";
import { useSelector } from "react-redux";
import axiosInstance from "./services/api";

function App() {
  const queryClient = new QueryClient();

  const authStatus = getLocal("auth");
  const userInfo = useSelector((state) => state.user?.userData);
  const userData = getLocal("user") || userInfo;

  // console.log = () => {};

  // console.log('authStatus', authStatus);
  // console.log('userData', userData);

  const updateTokensInLocalStorage = (accessToken, refreshToken) => {
    const updatedUserData = {
      ...userData,
      access_token: accessToken,
      refresh_token: refreshToken,
    };
    setLocal("user", updatedUserData);
  };

  const startTimer = () => {
    let remainingTime = 4 * 60;
    const timerId = setInterval(() => {
      remainingTime--;
      // console.log(`Remaining time: ${remainingTime} seconds`);

      if (remainingTime === 0) {
        clearInterval(timerId);
        refreshTokenCall();
      }
    }, 1000);
  };

  const refreshTokenCall = async () => {
    if (authStatus === true) {
      try {
        const response = await axiosInstance.post("/Customers/refreshtoken", {
          userId: userData?.userId,
          refreshToken: userData?.refresh_token,
        });
        if (response?.data?.responseCode === "00") {
          const { accessToken, refreshToken } = response?.data?.data;
          updateTokensInLocalStorage(accessToken, refreshToken);
          setAccessToken(accessToken);
          setRefreshToken(refreshToken);

          startTimer();
        } else {
          setLocal("auth", false);
          clearLocal("user");
          clearAccessToken();
          clearRefreshToken();
          // Handle error
        }
      } catch (error) {
        throw error;
      }
    } else {
      return;
    }
  };
  React.useEffect(() => {
    try {
      // Check if the environment supports Web Workers
      if (typeof Worker !== 'undefined') {
        console.warn('Web Workers are not supported in this environment.');
      }
       else {
        console.warn('Web Workers are not supported in this environment.');
      }
    } catch (error) {
      // Handle the error, for example by displaying a fallback UI or logging the error
    }
  }, []);

  React.useEffect(() => {
    if (authStatus === true) {
      startTimer();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus]);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router}></RouterProvider>
        <ReactQueryDevtools position="bottom-right" />
      </QueryClientProvider>
    </>
  );
}

export default App;
