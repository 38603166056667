import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  clearCookie,
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from '../../hooks/auth';
import axiosInstance from '../../services/api';
import { jwtDecode } from 'jwt-decode';
import { router } from '../../utils/router';
import { AppRoutes } from '../../utils/routes';

let refreshTokenTimer;

export const refreshTokenAsync = createAsyncThunk(
  'user/refreshToken',
  async () => {
    let token = getAccessToken();
    const refreshTokenData = getRefreshToken();
    let rToken = refreshTokenData.refreshToken;
    if (
      rToken === undefined ||
      rToken === 'undefined' ||
      !rToken ||
      token === undefined ||
      token === 'undefined' ||
      !token
    ) {
      router.navigate(AppRoutes.signin);
      return null;
    }
    try {
      const response = await axiosInstance.post('/Customers/refreshtoken', {
        userId: refreshTokenData.customerId,
        refreshToken: refreshTokenData.refreshToken,
      });
      if (response?.data?.responseCode === '00' || response?.data?.isSuccessful) {
        // console.log('Response', response);
        const { accessToken, refreshToken } = response?.data?.data;
        setAccessToken(accessToken);
        setRefreshToken(refreshToken, refreshTokenData.customerId);
        return accessToken;
      } else {
        router.navigate(AppRoutes.signin);
        return null;
      }
    } catch (error) {
      router.navigate(AppRoutes.signin);
      throw error;
    }
  }
);

const initialState = {
  userData: null,
  isAuthenticated: false,
  browserId: '',
  customerInfo: null,
  customerMatrix: null,
  wishlist: [],
  order: [],
};

export const login = createAsyncThunk(
  'user/login',
  async (userData, { dispatch }) => {
    const { refresh_token, userId, access_token } = userData;

    const decodedAccessToken = jwtDecode(access_token);

    if (decodedAccessToken) {
      const expirationTime = decodedAccessToken.exp * 10000;
      setRefreshToken(refresh_token, userId, expirationTime);
      setAccessToken(access_token);
      scheduleTokenRefresh(dispatch, expirationTime); // Schedule token refresh
    } else {
      const expirationTime = new Date().getTime() + 200 * 60 * 1000;
      setRefreshToken(refresh_token, userId, expirationTime);
      setAccessToken(access_token);
    }
    return userData;
  }
);

function scheduleTokenRefresh(dispatch, expirationTime) {
  const currentTime = new Date().getTime();
  const timeUntilRefresh = expirationTime - currentTime - 30 * 60 * 1000;
  if (timeUntilRefresh > 0) {
    clearTimeout(refreshTokenTimer);
    refreshTokenTimer = setTimeout(() => {
      dispatch(refreshTokenAsync());
    }, timeUntilRefresh);
  }
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    anon: (state, action) => {
      const browserId = action.payload;
      state.browserId = browserId;
    },
    logout: (state) => {
      clearCookie('refreshToken');
      clearCookie('accessToken');

      state.isAuthenticated = false;
      state.userData = null;
    },
    setAdditionalCustomerInfo: (state, action) => {
      state.customerInfo = action.payload;
    },
    setCustomerMatrix: (state, action) => {
      state.customerMatrix = action.payload;
    },
    setWishlist: (state, action) => {
      state.wishlist = action.payload;
    },
    setOrders: (state, action) => {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        const userData = action.payload;
        state.isAuthenticated = true;
        state.userData = userData;
      })
      .addCase(refreshTokenAsync.fulfilled, (state, action) => {});
  },
});

export const {
  anon,
  logout,
  setAdditionalCustomerInfo,
  setCustomerMatrix,
  setWishlist,
  setOrders,
} = userSlice.actions;

export default userSlice.reducer;
