import React from "react";
import { useIdleTimer } from "react-idle-timer";


export const useIdle = ({ onIdle, idleTime = 1 }) => {
  const [isIdle, setIsIdle] = React.useState();
  const [timedOut, setTimeout] = React.useState();
  const [showModal, setShowModal] = React.useState(false);
  

  const handleOnIdle = (event) => {
    if (timedOut) {
      onIdle();
      setShowModal(false);
    } else {
      setTimeout(()=>{ setIsIdle(true);
        setTimeout(true);
       // console.log("user is idle", event);
        setShowModal(true);
      //  console.log("Last Active", getLastActiveTime());
        }, 20000)
        reset()
    }

    // onIdle();
  };
 
  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: 1000 * 60 * idleTime,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return {
    getRemainingTime,
    getLastActiveTime,
    isIdle,
  };
};
