import { Box, Grid, GridItem, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Generators from '../../../assets/Home/categories/cat1-generator.png';
import Inverters from '../../../assets/Home/categories/cat2-inverter.jpg';
import Monitors from '../../../assets/Home/categories/cat3-monitor.png';
import Batteries from '../../../assets/Home/categories/cat4-battery.png';
import Solar from '../../../assets/Home/categories/cat5-solar.png';
import Bundles from '../../../assets/Home/categories/cat6-bundle.png';
import Ups from '../../../assets/Home/categories/cat7-ups.png';
import Stabilizer from '../../../assets/Home/categories/cat8-stabilizer.jpg';
import { CategoryRoutes } from '../../../utils/routes';

const Categories = () => {
  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/products/category/${id}`);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const categoryItems = [
    {
      id: '001',
      title: 'Generators & Accessories',
      routes: CategoryRoutes.gen,
      image: Generators,
    },
    {
      id: '002',
      title: 'Inverters',
      routes: CategoryRoutes.inverters,
      image: Inverters,
    },
    {
      id: '003',
      title: 'Monitors & Metering',
      routes: CategoryRoutes.monitors,
      image: Monitors,
    },
    {
      id: '004',
      title: 'Batteries',
      routes: CategoryRoutes.batteries,
      image: Batteries,
    },
    { id: '005', title: 'Solar', routes: CategoryRoutes.solar, image: Solar },
    {
      id: '006',
      title: 'Bundles',
      routes: CategoryRoutes.bundles,
      image: Bundles,
    },
    { id: '007', title: 'UPS', routes: CategoryRoutes.ups, image: Ups },
    {
      id: '008',
      title: 'Stabilizer',
      routes: CategoryRoutes.stabilizers,
      image: Stabilizer,
    },
  ];

  return (
    <>
      <Grid
        templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
        gap={8}
        mx={['20px', 0]}
      >
        {categoryItems?.map((item) => (
          <GridItem
            w='100%'
            h={['200px', '280px', '320px']}
            bg='white'
            key={item.id}
            borderRadius={'20px'}
            cursor={'pointer'}
            boxShadow={'0px 0px 17px rgba(244, 244, 244, 0.25)'}
            _hover={{ border: '3px solid #32C36C' }}
          >
            <Box
              onClick={() => handleClick(item.id)}
            >
              <Text textAlign={'center'} mt={'30px'} fontWeight={700}>
                {item.title.toUpperCase()}
              </Text>
              <Image src={item.image} alt={item.title} mx='auto' p='30px' />
            </Box>
          </GridItem>
        ))}
      </Grid>
    </>
  );
};

export default Categories;
