// 1. Import the extendTheme function
import { extendTheme } from '@chakra-ui/react'

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  brand: {
    10: '#32C36C',
    20: '#88D4A2',
    30: '#1A2A36',
    40: '#FBFBFB',
  },
}

export const theme = extendTheme({ colors })