import React, { Suspense, lazy } from 'react';
import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import SolarCalculator from '../../../assets/Home/general/solarcalc.png';
import LoadingModal from '../../../components/Modals/LoadingModal';

const InverterOffCanvas = lazy(() => import('./InverterOffcanvas'));

const InverterBox = ({ position }) => {
  return (
    <>
      <Box
        h={['210px', '180px']}
        bg='brand.10'
        borderRadius={'20px'}
        w={['100%', '450px']}
        position={position}
        right={4}
        bottom={'-60px'}
      >
        <HStack>
          <Image src={SolarCalculator} alt='Solar Calculator}' m='30px' />
          <VStack color={'white'} align={'flex-start'}>
            <Text fontSize={'28px'} fontWeight={600} mt='10px'>
              Inverter Calculator
            </Text>
            <Text lineHeight={1.2} fontSize={'14px'} mr='20px !important'>
              Calculate the power load you use and let us recommend an inverter
              that suites your need
            </Text>
            <Suspense fallback={<LoadingModal />}>
              <InverterOffCanvas />
            </Suspense>
          </VStack>
        </HStack>
      </Box>
    </>
  );
};

export default InverterBox;
